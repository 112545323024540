import React from 'react'
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import Layout from '../components/layouts'
import BlogPosts from '../components/BlogPosts'
import logo from '../images/logo.png'

// Query for the Blog Home content in Prismic
export const query = graphql`{
  prismic{
    allBlog_homes{
        edges{
            node{
                maintenance
                image
                imageSharp {
                    childImageSharp {
                        fluid(quality: 100) {
                            base64
                            aspectRatio
                            sizes
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            originalName
                        }
                    }
                }
            }
        }
    }
    allPosts(sortBy: date_DESC){
      edges{
        node{
          _meta{
            id
            uid
            type
            tags
          }
          title
          date
          hero_image
          hero_imageSharp {
            childImageSharp {
                fluid(quality: 100) {
                    base64
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    originalName
                }
            }
          }
          category {
            _linkType
            ... on PRISMIC_Categories {
              categories_page_title
              _linkType
              _meta {
                uid
              }
            }
          }
          body{
            __typename
            ... on PRISMIC_PostBodyText{
              type
              label
              primary{
                text
              }
            }
          }
        }
      }
    }
  }
}
`

export default ({ data }) => {
    // Define the Blog Home & Blog Post content returned from Prismic
    const doc = data.prismic.allBlog_homes.edges.slice(0, 1).pop();
    const limit = 5;
    const numPages = Math.ceil(data.prismic.allPosts.edges.length / limit);
    const posts = data.prismic.allPosts.edges.slice(0, limit);

    if (doc.node.maintenance) return (
        <div className="maintenance">
            <Img
                fluid={logo.childImageSharp.fluid}
                alt="Ce sa vizitam"
            />
        </div>
    )

    return (
        <Layout>
            <BlogPosts posts={posts} numPages={numPages} currentPage={1} />
        </Layout>
    )
}
